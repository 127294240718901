import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import logo from "../../content/assets/rappler-plus-dark.png";


class Layout extends React.Component {
  
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    
    let header
    if (location.pathname === rootPath) {
      header = (
        // <h1
        //   style={{
        //     ...scale(1.5),
        //     marginBottom: rhythm(1.5),
        //     marginTop: 0,
        //     fontFamily: `Roboto, sans-serif`,
        //     color: '#e76229'
        //   }}
        // >
        //   <Link
        //     style={{
        //       boxShadow: `none`,
        //       textDecoration: `none`,
        //       color: `inherit`,
        //     }}
        //     to={`/`}
        //   >
        //     {title}
        //   </Link>
        // </h1>
        <img src={logo} style={{maxWidth: "200px", width: "auto", height: "auto"}}></img>
      )
    } else {
      header = (
        // <h3
        //   style={{
        //     fontFamily: `Roboto, sans-serif`,
        //     marginTop: 0,
        //     color: '#e76229'
        //   }}
        // >
        //   <Link
        //     style={{
        //       boxShadow: `none`,
        //       textDecoration: `none`,
        //       color: `inherit`,
        //     }}
        //     to={`/`}
        //   >
        //     {title}
        //   </Link>
        // </h3>
        <Link to="/" style={{boxShadow:`none`}}><img src={logo} style={{maxWidth: "200px", width: "auto", height: "auto"}}></img></Link>
      )
    }

    let iconStyle = { 
      fontSize: "30px",
      width: "30px",
      textAlign: "center",
      textDecoration: "none",
      margin: "5px 2px",
      borderRadius: "50%",
      boxShadow: 'none'
    }

    let fbStyle = {
      background: "#3B5998",
      color: "white"
    }

    let twitterStyle = {
      background: "#55ACEE",
      color: "white"
    }

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <footer>
          {/* <Link className="fa fa-facebook" style={{...iconStyle, ...fbStyle}} to="https://www.facebook.com/rapplerdotcom/" target="__blank"></Link>
          <Link className="fa fa-twitter" style={{...iconStyle, ...twitterStyle}} to="https://twitter.com/rapplerdotcom" target="__blank"></Link> */}
        </footer>
      </div>
    )
  }
}

export default Layout
